import { createConfig } from '@config';
import type { IConfig } from '@config/types';
import envConfig from 'env-config';
import { getRegion } from 'services/auth/auth-storage-manager';

let config: IConfig | null = null;

export function getConfig(): IConfig {
  const region = getRegion();

  if (!region) {
    // create temporary config for unspecified region
    // sometimes getConfig() is called before region is set
    // todo: it would be wise to refactor so that region is always set
    return createConfig(envConfig);
  }

  if (!config) {
    config = createConfig(envConfig, region);
  }

  return config;
}
