import { getConfig } from 'helpers/config';

import connectivityStatus from './service';
import { type ConnectivityStatusInstance } from './types';

const baseURL: string | undefined = getConfig().api;

if (!baseURL) {
  // eslint-disable-next-line no-console
  console.warn('API base URL is not defined or is malformed in the configuration. Falling back to empty service URLs.');
}

type ServiceUrls = {
  agentApi: string;
  configurationApi: string;
  restApi: string;
};

const SERVICE_URLS: ServiceUrls | null = baseURL
  ? {
      agentApi: `${baseURL}/agent/status`,
      configurationApi: `${baseURL}/configuration/status`,
      restApi: `${baseURL}/service/alive`,
    }
  : null;

/**
 * In the future, replace the first part of condition with the following:
 * [SERVICE_URLS.agentApi, SERVICE_URLS.configurationApi, SERVICE_URLS.restApi]
 * Currently, we're setting to empty array as these health checks are not used.
 */
const serviceUrlsArray: string[] = SERVICE_URLS ? [] : [];

export const connectivityStatusClient: ConnectivityStatusInstance = connectivityStatus.create({
  servicesURLs: serviceUrlsArray,
});
