import { isError, isHTTPError } from './type-guards';
import { HTTPStatus, type ApiClientOptions } from './types';

const NETWORK_ERROR_MESSAGES = [
  'NetworkError when attempting to fetch resource.',
  'Failed to fetch',
  'Load failed',
  'Request timed out',
];

/**
 * Checks if the body contains file inputs.
 *
 * @param body - The request body to check.
 * @returns {boolean} - Returns true if the body contains file inputs, otherwise false.
 */
export function isFileUpload(body: BodyInit): boolean {
  if (!(body instanceof FormData)) {
    return false;
  }

  for (const value of body.values()) {
    if (
      (typeof File !== 'undefined' && value instanceof File) ||
      (typeof Blob !== 'undefined' && value instanceof Blob)
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Type definition for the options parameter of the setHeaders function.
 */
type SetHeadersOptions = Pick<ApiClientOptions, 'getCredentials' | 'getRegion' | 'authType'> & {
  request: Request | XMLHttpRequest;
};

/**
 * Sets the headers for a request object which can be either a Request object from the Fetch API or an XMLHttpRequest.
 * It conditionally sets the 'Authorization' and 'X-Region' headers based on the provided options.
 *
 * @param options - An object containing the options for setting headers.
 */
export const setHeaders = ({ request, getCredentials, getRegion, authType }: SetHeadersOptions): void => {
  const credentials = getCredentials ? getCredentials() : null;
  const region = getRegion ? getRegion() : null;

  if (request instanceof Request) {
    // Handle the Request type (Fetch API)
    if (credentials) {
      request.headers.set('Authorization', `${authType} ${credentials}`);
    }
    if (region) {
      request.headers.set('X-Region', region);
    }
  } else {
    if (credentials) {
      request.setRequestHeader('Authorization', `${authType} ${credentials}`);
    }
    if (region) {
      request.setRequestHeader('X-Region', region);
    }
  }
};

export function isNetworkIssueMessage(message: string): boolean {
  return NETWORK_ERROR_MESSAGES.some((networkErrorMessage) => message.startsWith(networkErrorMessage));
}

/**
 * Errors that qualify as network issues are those JavaScript runtime errors:
 *  - TypeError: Failed to fetch
 *  - TypeError: NetworkError when attempting to fetch resource.
 *  - TypeError: Load failed
 *  - TimeoutError: Request timed out: <method> <url>
 *
 * This method checks only raw errors thrown by API clients. It does not check normalized API errors.
 */
export function isNetworkIssue(error: unknown): error is Error {
  return isError(error) && isNetworkIssueMessage(error.message);
}

function isServerIssue(error: unknown): boolean {
  return (
    isHTTPError(error) &&
    [HTTPStatus.InternalServerError, HTTPStatus.TooManyRequests].includes(error.response.status as HTTPStatus)
  );
}

export function shouldRetryRequest(error: unknown): boolean {
  return isNetworkIssue(error) || isServerIssue(error);
}
